import React, { FC } from 'react';
import { Flex } from 'rebass';
import { Span, TitleText, BannerTitleText } from "./Typography";
interface HeadingProps {
  textColor: string;
  text: string;
  boldText?: string;
  boldTextColor?: string;
  fontFamily?: string;
  fontSize?: string;
  textFontWeight?: number | string;
  boldFontWeight?: number | string;
  underline?: boolean;
  unlineColor?: string;
  underLineWidth?: string;
  textAlign?: string;
  lineHeight?: number;
  margin?: string;
  textAlignMob?: string;
  HeadingType?: string;
}
const Heading: FC<HeadingProps> = ({ margin, textAlign, lineHeight, underLineWidth, text, textColor, boldText, boldTextColor, textFontWeight, fontFamily, boldFontWeight, fontSize, underline, unlineColor, textAlignMob = "left", HeadingType }) => {

  return (
    <Flex alignItems='center'>
      {HeadingType === "banner" ?
        <BannerTitleText textAlignMob={textAlignMob}
          margin={margin}
          fontFamily={fontFamily}
          textAlign={textAlign}
          lineHeight={lineHeight}
          underline={underline}
          underLineWidth={underLineWidth}
          unlineColor={unlineColor}
          fontWeight={textFontWeight}
          textColor={textColor}
          fontSize={fontSize}>{text}
          <Span lineHeight={lineHeight}
            fontFamily={fontFamily}
            fontWeight={boldFontWeight}
            textColor={boldTextColor} fontSize={fontSize}> {boldText} </Span></BannerTitleText>
        :
        <TitleText textAlignMob={textAlignMob}
          margin={margin}
          fontFamily={fontFamily}
          textAlign={textAlign}
          lineHeight={lineHeight}
          underline={underline}
          underLineWidth={underLineWidth}
          unlineColor={unlineColor}
          fontWeight={textFontWeight}
          textColor={textColor}
          fontSize={fontSize}>{text}
          <Span lineHeight={lineHeight}
            fontFamily={fontFamily}
            fontWeight={boldFontWeight}
            textColor={boldTextColor} fontSize={fontSize}> {boldText} </Span></TitleText>
      }
    </Flex>
  );
};

export default Heading;
